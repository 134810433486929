import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { styled } from "twin.macro"
import { useAuth } from "../context/auth"
import GlobalStyling from "../components/utils/GlobalStyling"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import Spinner from "react-bootstrap/Spinner"


import agri from "../images/agri.png"
import logo from "../images/logo.png"

import Seo from "../components/seo"

const LoginWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: inherit;
	width: inherit;
`

const LoginContainner = styled.div`
	display: flex;
	width: 750px;
`

const LoginImg = styled.div`
	flex-basis: 50%;
	height: 500px;
	background-image: url(${agri});
	background-repeat: no-repeat;
`

const LoginInputDiv = styled.div`
	height: 500px;
	flex-basis: 50%;
	width: 100%;
	background: white;
	padding: 0 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`
const LoginLogo = styled.div`
	background: url(${logo});
	background-repeat: no-repeat;
	height: 100px;
	width: 100%;
`

const ForgetPassword = styled.div`
	font-family: "Montserrat";
	margin-top: 25px;
`
const Anchor = styled(Link)`
	color: #697386;
	text-decoration: underline;
	font-weight: 600;
    font-size: 13px;
	&:hover {
		color: #343a40;
	}
`

/**boostrap Modified component */


const BForm = styled(Form)`
	width: 100%;
	font-family: "Montserrat";
`
const FormLabel = styled(Form.Label)`
	font-size: 14px;
    font-weight: 600;
	color: #697386;
`

const FormControl = styled(Form.Control)`
	font-size: 14px;
`

const BButton = styled(Button)`
	background: #0d8a72;
	width: 100%;
	font-size: 14px;
	border: none;
	&:hover {
		background: #0b6d5a;
	}
	&:focus {
		background: #0b6d5a;
		border: none;
	}
	&:disabled {
		background: #0b6d5a;
		border: none;
		cursor: default;
	}
	&:not(:disabled):not(.disabled):active {
		background: #0b6d5a;
		border: none;
	}
`

const BAlert = styled(Alert)`
	font-family: "Montserrat";
    font-size: 14px;
`

const AgriSpinner = styled(Spinner)`
	background: #0d8a72;
`

/** */

const ResetAccountPage = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [checking, setChecking] = useState(true)
	const [error, setError] = useState("")
	const [message, setMessage] = useState("")
	const auth = useAuth()

	const handleSubmit = async (e) => {
		e.preventDefault();
		const {email} = e.target.elements

		/** validaition till now use only HTML validation */

		try {
			setError("")
			setMessage("")
			setIsLoading(true)
			await auth.reset(email.value)
			setMessage("please check your email") // to refactor
		} catch (error) {
			setMessage("")
			setError("Vous avez saisi une adresse mail incorrecte.")
		}
		setIsLoading(false)
	}

	useEffect(() => {
		if (auth.currentUser){
			navigate("/dashboard", {replace: true})
		}
		setInterval(() => (
			setChecking(false)
		), 1337)
		
		return () => (setChecking(true))
	}, [])

	/** 
	 * Run a loader while checking if there is a current user in the context
	 * if there is one then navigate to "/dashboard" else render the login form
	 * and we gonna add "/resetaccount" for resting passwords
	 */
	if (checking){
		return(
			<>
				<GlobalStyling/>
				<LoginWrapper>
					<AgriSpinner animation="grow" />
				</LoginWrapper>
			</>
		)
	}
	return (
	<React.Fragment>
		<Seo title="Login" />
		<GlobalStyling/>
		<LoginWrapper>
			<LoginContainner>
				<LoginImg />
				<LoginInputDiv>
					<LoginLogo/>
					{message && <BAlert variant="success">{message}</BAlert>}
					{error && <BAlert variant="danger">{error}</BAlert>}
					<BForm onSubmit={handleSubmit}>
						<Form.Group>
							<FormLabel htmlFor="email">Email</FormLabel>
							<FormControl type="email" placeholder="e-mail@name.com" id="email" required/>
						</Form.Group>
						<BButton type="submit" disabled={isLoading}>
							{isLoading ? 'Loading…' : 'Envoyer'}
						</BButton>
					</BForm>
					<ForgetPassword>
					<Anchor to="/">Retour a la page d'accueil</Anchor>
					</ForgetPassword>
				</LoginInputDiv>
			</LoginContainner>
		</LoginWrapper>
	</React.Fragment>
	)
}

export default ResetAccountPage
